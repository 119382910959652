<template>
  <div class="favorites-add-mians">
    <div class="favorites-add-mian">
      <el-dialog
        title="收藏管理"
        :visible.sync="visible"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="favorites-add-cen">
          <vue-scroll :ops="ops">
            <div class="favorites-add-items" v-for="(item,index) in folderData" :key="index">
              <div>
                <el-checkbox v-model="item.is_favorite"></el-checkbox>
              </div>
              <div>{{item.group_name}}</div>
              <div>{{item.total}}</div>
            </div>
          </vue-scroll>
        </div>
        <!-- 添加收藏夹 -->
        <div class="favorites-add-but" v-if="addShowTrue == true" @click="addShowTrue=false">
          <!-- <img src="../../../public/index/tianjia.png" /> -->
          <i class="el-icon-circle-plus-outline"></i>
          <div>{{$t('添加收藏夹')}}</div>
        </div>
        <!-- 创建文件夹名称 -->
        <div style="display: flex;flex-flow: row;margin-top: 1.25rem;" v-else>
          <el-input placeholder="分组" :maxlength="20" v-model="addValue" style="width: 16.25rem;">
            <i slot="prefix" class="el-input__icon el-icon-circle-plus-outline"></i>
          </el-input>
          <el-button type="primary" plain @click="createAFolder()">{{$t('创建')}}</el-button>
          <!-- <div
            style="width: 68px;height: 40px;background: #3e68ff;opacity: 0.8;color: #FFFFFF;display: flex;flex-flow: row;justify-content: center;align-items: center;"
            @click="createAFolder()"
          >{{$t('创建')}}</div>-->
        </div>
        <div class="favorites-add-confirm">
          <el-button type="primary" @click="saveChange">{{$t('确认')}}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    companyId: {},
  },
  data() {
    return {
      dialogGenerate: false,
      folderData: [],
      cancelGroup_id: [], //修改id
      group_id: '',
      addValue: '',
      addShowTrue: true,
      ops: {
        //滚动条
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#F5F5F5', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      originalArray: [], // 操作原数组
      theGoalArrayGroupId: [],
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.userFolder()
      }
    },
  },
  methods: {
    handleClose() {
      this.folderData = []
      this.$emit('update:visible', false)
    },
    // 页面加载获取文件夹
    userFolder() {
      if (!this.companyId) return
      let params = {
        company_id: this.companyId,
      }
      this.$service.getGroupList(params).then((res) => {
        this.folderData = res.collection_group_vo
        this.originalArray = res.collection_group_id //目标愿数组
      })
    },
    //修改文件夹
    saveChange() {
      let target_groups_id = []
      let company_id = this.companyId
      this.folderData.map((i) => {
        if (i.is_favorite) {
          target_groups_id.push(i.ID)
        }
      })
      let params = {
        companies_id: [company_id],
        source_group_id: this.originalArray,
        target_groups_id: target_groups_id,
      }
      this.$service
        .postCollectionManagement(params)
        .then(() => {
          this.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
          })
          this.handleClose()
          let res = {
            company_id: company_id,
            let: target_groups_id,
          }
          this.$emit('changeSuccess', res)
        })
        .catch((err) => {
          this.$notify({
            title: '失败',
            message: err.message,
            type: 'error',
          })
        })
      return
    },
    // 创建文件夹
    createAFolder() {
      let params = {
        group_name: this.addValue.trim(),
      }
      if (!params.group_name) {
        this.$notify({
          title: '失败',
          message: this.$t('收藏夹名称不能为空'),
          type: 'error',
        })
        return
      }
      this.$service
        .postCreatGroup(params)
        .then((result) => {
          this.$notify({
            title: '成功',
            message: this.$t('创建成功'),
            type: 'success',
          })
          this.addValue = ''
          this.userFolder()
          this.addShowTrue = true
        })
        .catch((err) => {
          this.$notify({
            title: '失败',
            message: err.message,
            type: 'error',
          })
        })
      return
    },
  },
}
</script>

<style lang="scss" scoped>
// 滚动条
::deep .__bar-is-vertical {
  right: -1px !important;
}

// 隐藏横向滚动条
::deep .__bar-is-horizontal {
  display: none !important;
}

.favorites-add-mians {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

// 样式穿透
::v-deep .favorites-add-mian .el-dialog__wrapper {
  width: 100%;
}

::v-deep .favorites-add-mian .el-dialog {
  border-left: 0.5rem solid #3e68ff;
  width: 22.5rem;
}

::v-deep .favorites-add-mian .el-dialog__body {
  padding: 0px 1.25rem;
  border-top: 0.0625rem solid #fafafb;
}

::v-deep .favorites-add-mian .el-dialog__header {
  padding: 1.25rem 1.25rem 0.3125rem;
}

.favorites-add-cen {
  display: flex;
  flex-flow: column;
  height: 19.625rem;
  border-bottom: 0.0625rem solid #fafafb;
}

.favorites-add-items {
  display: flex;
  flex-flow: row;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2.5rem;
  align-items: center;
}

.favorites-add-items > div:nth-child(1) {
  width: 30px;
}
.favorites-add-items > div:nth-child(2) {
  flex: none;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.favorites-add-items > div:nth-child(3) {
  flex: 1;
  text-align: right;
  padding-right: 10px;
}

.favorites-add-but {
  height: 2.625rem;
  border: 1px solid #fafafb;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-left: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.favorites-add-but > img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.favorites-add-confirm {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-bottom: 24px;
}

.el-icon-circle-plus-outline {
  font-size: 24px;
  margin-right: 4px;
  color: #257cff;
  &.el-input__icon {
    color: #999;
  }
}
</style>
