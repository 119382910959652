<template>
  <div class="company-card-list">
    <div class="mian-sho-list" v-if="contentData != '' && contentData != null">
      <div class="shop-cen-info" v-for="item in contentData" :key="item.company.ID">
        <!-- 头部公司 -->
        <div class="hade-the-company">
          <span @click="detailsCk(item.company.ID)">{{item.company.name}}</span>
          <div
            v-for="(bonusitem,bonusIndex) in bonusFilter(item.bonus)"
            :key="item.company.ID+'bonus'+bonusIndex"
            :class="'company-tag'+' company-tag_'+bonusitem.ID"
          >{{bonusitem.name}}</div>
        </div>
        <!-- //基本信息 -->
        <div class="shop-cen-infos">
          <div>
            <div>{{$t('统一编号')}}：{{item.company.uniform_numbers}}</div>
            <div>{{$t('登记现状')}}：{{item.company.status}}</div>
            <div>{{$t('电话')}}：{{item.company.phone}}</div>
          </div>
          <div>
            <div>法人：{{item.company.representative_name}}</div>
            <div>成立日期：{{item.company.date_of_establishment}}</div>
            <div>{{$t('电子邮件')}}：{{item.company.email}}</div>
          </div>
          <div>
            <div>{{$t('资本总额(新台币)')}}：{{item.company.total_capital|formatNumber}}</div>
            <div>{{$t('公司官网')}}：{{item.company.website}}</div>
            <div></div>
          </div>
        </div>
        <!-- 营业项目 -->
        <div class="business">
          <div>{{$t('营业项目')}}：{{item.company.business_item}}</div>
        </div>
        <!-- //地址 -->
        <div style="margin-bottom: 0.125rem;">
          <div>地址：{{item.company.region_province}}{{item.company.region_city}}{{item.company.region_county}}{{item.company.region_details}}</div>
        </div>
        <!-- 产品信息 -->
        <div class="product-info">
          <div>{{$t('产品信息')}}:</div>
          <div class="product-info-items" v-if="item.products">
            <div
              class="product-info-item"
              v-for="(productsitem,productsIndex) in item.products.slice(0,3)"
              :key="productsIndex"
              @click="detailsCk(item.company.ID,true)"
            >
              <img
                :src="'../'+productsitem.product_image"
                v-if="productsitem.product_image&&productsitem.product_image!=='-'"
              />
              <img v-else src="@/assets/images/detail/que.png" />
              <div v-if="productsitem.product_category">{{productsitem.product_category}}</div>
            </div>
          </div>
        </div>
        <!-- 右边部分收藏按钮 -->
        <div class="collect-btn" @click="clickCollectionManagement(item.company)">
          <i
            v-if="item.collection_group_id && item.collection_group_id.length > 0"
            class="el-icon-star-on active"
          ></i>
          <i v-else class="el-icon-star-on"></i>
        </div>
        <!-- 弹窗 -->
      </div>
      <favoritesAdd
        :visible.sync="collectVisible"
        :companyId="collectCompanyId"
        @changeSuccess="changeSuccess"
      ></favoritesAdd>
    </div>
    <div v-else-if="!loading" class="empty-c">
      <img src="@/assets/images/emptyc.png" />
      <div>{{$t('暂时没有相关数据')}}！</div>
    </div>
  </div>
</template>

<script>
import favoritesAdd from '../../components/modeOpen/favorites_add.vue'
export default {
  props: {
    contentData: {
      default: () => [],
    },
    companies_id: {},
    loading: Boolean,
  },
  data() {
    return {
      items: [],
      isshow: false,
      addShow: true,
      companyRes: [], //开启取消弹窗参数
      collectVisible: false,
      collectCompanyId: '',
      originalArray: [], // 操作原数组
      theGoalArray: [], //目标数组
    }
  },
  methods: {
    // 标签去重
    bonusFilter(val) {
      let data = []
      if (val) {
        val.map((item) => {
          if (!data.find((o) => o.ID === item.ID)) {
            data.push(item)
          }
        })
      }
      return data
    },
    // 详情页面跳转
    detailsCk(e, product = false) {
      const { href } = this.$router.resolve({
        path: '/details',
        query: {
          id: e,
          product,
        },
      })
      window.open(href, '_blank')
    },
    // 收藏管理
    clickCollectionManagement(val) {
      this.collectCompanyId = val.ID
      this.collectVisible = true
    },

    changeSuccess(val) {
      this.$emit('fatherCk', val)
      return
    },
  },
  components: {
    favoritesAdd,
  },
}
</script>

<style lang="scss" scoped>
.company-card-list {
  min-height: 300px;
  font-size: 14px;
}
.mian-sho-list {
  display: flex;
  flex-flow: column;
  background: #fff;
}
/* // 组件 */
.shop-cen-info {
  position: relative;
  color: #666;
  border-bottom: 1px solid #f2f2f2;
  margin: 0rem 1rem;
  padding-bottom: 1.5rem;
  font-size: 12px;
  .collect-btn {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;

    .el-icon-star-on {
      font-size: 42px;
      color: #d2d2d2;
    }
    .active {
      color: #f89941;
    }
  }
}

/* // 公司 */
.hade-the-company {
  display: flex;
  flex-flow: row;
  height: 4rem;
  align-items: center;
}

.hade-the-company > span:first-of-type {
  font-size: 1.5rem;
  font-weight: 800;
  color: #111111;
  line-height: 4rem;
  margin-right: 2rem;
  cursor: pointer;
}

/* 	// 公司基本信息 */
.shop-cen-infos {
  display: flex;
  flex-flow: row;
  height: 3.875rem;
}

/* 	// 公司基本信息 */
.shop-cen-infos {
  display: flex;
  flex-flow: row;
  height: 3.875rem;
}

.shop-cen-infos > div {
  display: flex;
  flex-flow: column;
  margin-right: 4rem;
}

.shop-cen-infos > div > div:nth-child(2) {
  margin: 0.25rem 0rem;
}

/* //营业项目 */
.business {
  margin-top: 0.5rem;
  margin-bottom: 0.125rem;
}

/* // 产品信息 */
.product-info {
  display: flex;
  flex-flow: column;
}

.product-info-items > div {
  display: flex;
  flex-flow: column;
  margin-right: 2.5rem;
}

.product-info-items {
  display: flex;
  flex-flow: row;
}

.product-info-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  margin-right: 64px;
  cursor: pointer;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.product-info-item > div {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
  background: rgba($color: #000000, $alpha: 0.3);
  color: #ffffff;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

//为空
.empty-c {
  height: 43.75rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.empty-c > img {
  width: 25.5625rem;
  height: 16.9375rem;
}

.empty-c > div {
  font-size: 1.125rem;
  font-weight: 600;
  color: #999999;
  line-height: 1.5625rem;
  margin-top: 2.1875rem;
}
</style>
