<template>
  <div class="search-list">
    <!-- 全部结果 -->
    <div class="result-All">
      <div>
        <div>{{$t('全部结果')}}</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div>
        <!-- 搜索条件 -->
        <el-tag
          size="small"
          closable
          type="info"
          v-if="searchData.name||searchData.uniform_numbers"
          @close="closeName"
        >搜索:{{searchData.name||searchData.uniform_numbers}}</el-tag>
        <!-- 地区 -->
        <el-tag
          size="small"
          closable
          type="info"
          v-if="addressLabel"
          @close="changeCity()"
        >{{$t('地区')}}:{{addressLabel}}</el-tag>
        <!-- 行业分类标签 -->
        <el-tag
          size="small"
          closable
          type="info"
          v-if="industryLabel"
          @close="closeIndustryLabel(0)"
        >{{industryLabel.industry_name}}</el-tag>
        <!-- 资本规模 -->
        <el-tag
          size="small"
          closable
          type="info"
          v-if="capitalLabel"
          @close="closeCapital"
        >{{$t('资本规模')}}:{{capitalLabel}}</el-tag>
        <!-- 公司类型 -->
        <el-tag
          size="small"
          closable
          type="info"
          v-for="(item,index) in companyTypeLabel"
          :key="index"
          @close="closeCompanyType(index)"
        >{{item.type}}</el-tag>
        <!-- 相关数组展示 -->
        <el-tag
          size="small"
          closable
          type="info"
          v-for="(item,index) in relatedTagLabel"
          :key="item.id"
          @close="closeRelateTag(index)"
        >{{$t('标签')}}:{{item.name}}</el-tag>
      </div>
    </div>
    <el-form class="form" ref="form" label-width="80px" label-position="left" size="mini">
      <!-- 地区 -->
      <el-form-item :label="$t('地区')">
        <el-select placeholder="不限" clearable v-model="searchData.city_id" @change="changeCity">
          <el-option
            v-for="item in cityList"
            :value="item.id"
            :label="item.region"
            :key="item.id"
          >{{item.region}}</el-option>
        </el-select>
        <el-select
          v-if="countyList.length"
          placeholder="不限"
          clearable
          v-model="searchData.county_id"
          @change="changeCounty"
        >
          <el-option
            v-for="item in countyList"
            :value="item.id"
            :label="item.region"
            :key="item.id"
          >{{item.region}}</el-option>
        </el-select>
      </el-form-item>
      <!-- 行业 -->
      <el-form-item :label="$t('行业分类')">
        <el-select
          v-model="industry[0]"
          clearable
          placeholder="不限"
          @change="changeIndustry($event,0)"
          @clear="closeIndustryLabel(0)"
        >
          <el-option
            v-for="item in industryList[0]"
            :value="item.industry_code"
            :label="item.industry_name"
            :key="item.industry_code"
          >{{item.industry_name}}</el-option>
        </el-select>
        <i class="el-icon-arrow-right"></i>
        <el-select
          v-model="industry[1]"
          clearable
          placeholder="不限"
          @change="changeIndustry($event,1)"
          @clear="closeIndustryLabel(1)"
        >
          <el-option
            v-for="item in industryList[1]"
            :value="item.industry_code"
            :label="item.industry_name"
            :key="item.industry_code"
          >{{item.industry_name}}</el-option>
        </el-select>
        <i class="el-icon-arrow-right"></i>
        <el-select
          v-model="industry[2]"
          clearable
          placeholder="不限"
          @change="changeIndustry($event,2)"
          @clear="closeIndustryLabel(2)"
        >
          <el-option
            v-for="item in industryList[2]"
            :value="item.industry_code"
            :label="item.industry_name"
            :key="item.industry_code"
          >{{item.industry_name}}</el-option>
        </el-select>
        <i class="el-icon-arrow-right"></i>
        <el-select
          v-model="industry[3]"
          clearable
          placeholder="不限"
          @change="changeIndustry($event,3)"
          @clear="closeIndustryLabel(3)"
        >
          <el-option
            v-for="item in industryList[3]"
            :value="item.industry_code"
            :label="item.industry_name"
            :key="item.industry_code"
          >{{item.industry_name}}</el-option>
        </el-select>
        <i class="el-icon-arrow-right"></i>
        <el-select
          v-model="industry[4]"
          clearable
          placeholder="不限"
          @change="changeIndustry($event,4)"
          @clear="closeIndustryLabel(4)"
        >
          <el-option
            v-for="item in industryList[4]"
            :value="item.industry_code"
            :label="item.industry_name"
            :key="item.industry_code"
          >{{item.industry_name}}</el-option>
        </el-select>
      </el-form-item>
      <!-- 资本规模 -->
      <el-form-item :label="$t('资本规模')">
        <el-select v-model="capitalScale" @change="selectCapital">
          <el-option
            v-for="item in capitalScaleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 公司类型 -->
      <el-form-item :label="$t('公司类型')">
        <el-checkbox-group v-model="checkedCompanyType" @change="changeCompanyType">
          <el-checkbox
            v-for="(item,index) in companyTypeList"
            :key="index"
            :label="item.ID"
          >{{item.type}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- 相关标签 -->
      <el-form-item :label="$t('相关标签')">
        <el-checkbox-group v-model="checkRelatedTag" @change="changeRelatedTag">
          <el-checkbox
            v-for="item in relatedTagList"
            :key="item.id"
            :label="item.id"
            :checked="item.is_chekd"
          >{{item.name}}{{item.is_chekd}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//组件引入
export default {
  props: {
    cate: {
      default: '',
    },
    searchValue: String,
  },
  data() {
    return {
      //地区分类
      addressLabel: '',
      cityList: [],
      countyList: [],

      // 行业类别标签
      // industryLabel: [],
      // 行业分类
      industryList: [[], [], [], [], []],
      industry: ['', '', '', '', ''],

      // 资本规模
      capitalScale: '',
      // 公司类型
      checkedCompanyType: [],
      // 公司标签数组
      companyTypeList: [],

      //标签数组
      checkRelatedTag: [],
      // 相关标签列表
      relatedTagList: [],

      // 提交数据
      searchData: {
        page: 1,
        type: [],
        total_capital_type: null,
        labels: [], //标签数组
        bonus: [], //奖补数组
        parent_path: '', // 行业分类
        city_id: null,
        county_id: null,
        name: '',
        uniform_numbers: '',
      },
      // 防抖定时器
      timer: null,
    }
  },

  watch: {
    '$i18n.locale'() {
      this.init()
    },
    searchValue(val) {
      let exp = /\d{7,8}/
      if (exp.test(val)) {
        this.searchData.name = ''
        this.searchData.uniform_numbers = val
      } else {
        this.searchData.name = val
        this.searchData.uniform_numbers = ''
      }
    },
    searchData: {
      handler(val) {
        this.debounce(() => {
          this.$emit('searchDataCk', val)
        }, 500)
      },
      deep: true,
    },
    cate(val) {
      let industry = []
      let arr = val.split('/')
      for (let i = 1; i < arr.length - 1; i++) {
        industry.push(arr[i])
      }
      for (let j = 0; j < 5; j++) {
        if (!industry[j]) {
          industry.push('')
        }
      }
      this.industry = industry
      this.initIndustry()
    },
  },
  computed: {
    // 相关标签label
    relatedTagLabel() {
      let result = []
      this.relatedTagList.map((i) => {
        if (this.checkRelatedTag.includes(i.id)) {
          result.push(i)
        }
      })
      return result
    },
    companyTypeLabel() {
      let result = []
      this.companyTypeList.map((i) => {
        if (this.checkedCompanyType.includes(i.ID)) {
          result.push(i)
        }
      })
      return result
    },
    capitalScaleOptions() {
      return [
        {
          value: 1,
          label: `0-1000${this.$t('万')}`,
        },
        {
          value: 2,
          label: `1000${this.$t('万')}-3000${this.$t('万')}`,
        },
        {
          value: 3,
          label: `3000${this.$t('万')}-1${this.$t('亿')}`,
        },
        {
          value: 4,
          label: `1${this.$t('亿')}以上`,
        },
      ]
    },
    industryLabel() {
      let level = this.industry.findIndex((i) => i == '')
      if (level == -1) {
        level = 4
      } else if (level == 0) {
        return ''
      } else {
        level -= 1
      }

      let industryList = this.industryList[level]
      let industry = this.industry[level]
      return industryList.find((o) => o.industry_code === industry)
    },
    capitalLabel() {
      let option = this.capitalScaleOptions.find(
        (i) => i.value == this.capitalScale
      )
      return option ? option.label : ''
    },
  },
  // 页面加载事件
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //加载地区
      this.getAreaList(0).then((res) => {
        this.cityList = res
      })
      // 加载行业分类
      this.getIndustryList('/').then((res) => {
        this.$set(this.industryList, 0, res)
      })
      this.initIndustry()
      this.getCompanyTypeList()
      // 加载公司相关标签
      this.getRelatedTagList()
    },
    // 防抖
    debounce(fn, delay) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = setTimeout(fn, delay)
      } else {
        this.timer = setTimeout(fn, delay)
      }
    },
    // 获取相关标签列表
    getRelatedTagList() {
      this.$service.getRelatedTagList().then((res) => {
        this.relatedTagList = res
      })
    },
    // 获取地区列表
    getAreaList(id) {
      let params = {
        parent_id: id,
      }
      return this.$service.getAreaList(params)
    },
    // 选择市
    changeCity(val) {
      this.countyList = []
      this.changeCounty()
      if (val) {
        this.getAreaList(val).then((res) => {
          this.countyList = res
        })
      } else {
        this.searchData.city_id = null
        this.searchData.county_id = null
        this.addressLabel = ''
      }
    },
    // 选择县
    changeCounty(val = null) {
      this.searchData.county_id = val ? val : null
      let city = this.cityList.find((o) => o.id === this.searchData.city_id)
      let county = this.countyList.find(
        (o) => o.id === this.searchData.county_id
      )
      this.addressLabel =
        (city ? city.region : '') + (county ? county.region : '')
    },
    // 获取行业列表
    getIndustryList(path) {
      let params = {
        parent_path: path,
      }
      return this.$service.getIndustryList(params)
    },
    // 行业初始化
    initIndustry() {
      for (let i = 0; i < 4; i++) {
        if (this.industry[i]) {
          let path = this.getCurrentPath(i)
          this.getIndustryList(path).then((res) => {
            this.$set(this.industryList, i + 1, res)
          })
        }
      }
    },
    // 切换行业
    changeIndustry(data, level) {
      if (level <= 4) {
        this.industry[level + 1] = ''
        this.changeIndustry('', level + 1)
        let path = this.getCurrentPath(level)
        if (level < 4) {
          if (data) {
            // 获取下一层数据
            this.getIndustryList(path).then((res) => {
              this.$set(this.industryList, level + 1, res)
            })
          } else {
            this.$set(this.industryList, level + 1, [])
          }
        }
      }
    },
    // 获取请求路径
    getCurrentPath(level) {
      const pathArr = this.industry.slice(0, level + 1)
      let path = '/' + pathArr.join('/').replace(/\/{2,}|(\/$)/, '')
      if (path.length > 1) {
        path += '/'
      }
      this.searchData.parent_path = path.length == 1 ? '' : path
      return path
    },
    // 关闭行业类别
    closeIndustryLabel(level) {
      this.industry[level] = ''
      this.changeIndustry('', level)
    },
    // 生成资本值标签
    selectCapital() {
      this.searchData.total_capital_type = this.capitalScale
        ? this.capitalScale
        : null
    },
    //关闭资本规模
    closeCapital() {
      this.capitalScale = ''
      this.searchData.total_capital_type = null
    },

    // 勾选公司类型
    changeCompanyType(e) {
      this.searchData.type = this.checkedCompanyType
    },

    // 关闭公司类型
    closeCompanyType(e) {
      this.checkedCompanyType.splice(e, 1)
      this.searchData.type = this.checkedCompanyType
    },

    //勾选相关标签
    changeRelatedTag(e) {
      this.searchData.bonus = e
    },
    // 关闭相关标签
    closeRelateTag(e) {
      this.checkRelatedTag.splice(e, 1)
      this.searchData.bonus = this.checkRelatedTag
    },
    // 进入银发友善食品奖
    labelLoad(id) {
      if (!this.checkRelatedTag.find((i) => i == id)) {
        this.checkRelatedTag.push(id)
        this.searchData.bonus = this.checkRelatedTag
      }
    },
    closeName() {
      this.searchData.name = ''
      this.searchData.uniform_numbers = ''
      this.$emit('cancelSearchValue')
    },
    // 获取公司类型列表
    getCompanyTypeList() {
      this.$service.getCompanyTypeList().then((res) => {
        this.companyTypeList = res
      })
    },
    // 重置搜索栏
    resetForm(val) {
      if (val != 'name') {
        this.closeName()
      }
      this.changeCity()
      this.closeIndustryLabel(0)
      this.closeCapital()
      this.checkedCompanyType = []
      this.searchData.type = []
      this.checkRelatedTag = []
      this.searchData.bonus = []
    },
  },
}
</script>

<style lang="scss" scoped>
.search-list {
  padding: 0 16px 1px;
  margin-top: 20px;
  font-size: 14px;
  color: #666666;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  background: #ffffff;

  .result-All {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 2.625rem;
    margin-bottom: 10px;
    font-size: 0.875rem;
    color: #999999;
  }

  .el-tag--small + .el-tag--small {
    margin-left: 12px;
  }

  .result-All > div:first-of-type {
    font-size: 0.875rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-right: 0rem;
    width: 5rem;
  }

  .result-All > div:first-of-type > i {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .form {
    .el-form-item {
      .el-input,
      .el-select {
        width: 160px;
        margin-right: 12px;
      }
      .el-select:not(:first-of-type) {
        margin-left: 12px;
      }
      .el-input:not(:first-of-type) {
        margin-left: 12px;
      }
    }
    ::v-deep .el-form-item__label {
      color: #999;
    }
  }
}
</style>
